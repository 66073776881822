var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

//var carouselBackgroundVideoYouTubeID = 'J6O4BFiysK8';

var countUpStats = true;
var countUpStatsDuration = 3000;

var homeQuickGivingPreFooter = true;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 768;
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 8000;

var subsiteHijackMainNav = ['thornbeck-college', 'employment-futures-home', 'diversity-ne', 'kiora-hall', 'student-mentoring-service', 'thornhill-park-school-neas', 'family-development', 'mackenzie-thorpe-centre', 'acceptance-matters', 'aycliffe-school-neas', 'progressne', 'diverse-pathways', 'neurodiversity-employment-service'];
var subsiteHijackMainLogoClickDestination = ['thornbeck-college', 'employment-futures-home', 'diversity-ne', 'kiora-hall', 'student-mentoring-service', 'thornhill-park-school-neas', 'family-development', 'mackenzie-thorpe-centre', 'aycliffe-school-neas', 'progressne', 'diverse-pathways', 'neurodiversity-employment-service'];
var subsiteTitleNotClickable = true;

var lualBodyClasses = ["PostCategory_light-up-a-life"];
var visualAppealsLoadMore = true;

$(document).ready(function () {

  if ($('body').hasClass('PostCategory_light-up-a-life')) {
    $(".appealTotaliser.totaliserPanel").insertBefore(".commentsList--printed");
  }
})

// Run function on resize
$(document).ready(function () {

  // Basket link in header 
  $('body[class*="roduct"] .menuAdminContainer .menuAdminBasket a').prependTo('.mainCallToActionButtons');

  // Subsite Diversity NE

  if ($('body.homepage').hasClass('diversity-ne') || $('body.homepage').hasClass('progressne') || $('body.homepage').hasClass('diverse-pathways') || $('body.homepage').hasClass('neurodiversity-employment-service')) {
    $('.subsiteBody table').insertBefore(".headerWrapper.headerWrapperSubsite");
  }

});


// Slick slider - Setup
$('.homeFeed:not(.subsiteFeed)').find('.feedList').slick({
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  centerMode: true,
  arrows: true,
  autoplay: true,
  autoplayspeed: 10000,
  responsive: [
    {
      breakpoint: 1024,
    },
    {
      breakpoint: 999999999,
      settings: 'unslick'
    }
  ]
});


// Footer logos
$('.footerBox[class*="ogos"]')
  .insertAfter('.pageFooterWrapper')
  .slick({
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 2,
    centerMode: false,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplayspeed: 8000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    responsive: [
      {
        breakpoint: 576,
      },
      {
        breakpoint: 999999999,
        settings: 'unslick'
      }
    ]
  });


// Post content image full screen
$('body:not(.homepage) .postContent p').each(function () {
  $(this).has('img.full-screen').addClass("hasImgFullScreen");
});


// Bespoke FAQ listing page
$('.listedFaq.PostCategory_how-you-register-your-interest').each(function () {
  $(this).addClass('accordianOpen');
});

// https://raisingit.atlassian.net/browse/IM-950
$('document').ready(function () {
  setTimeout(() => {
    if ($('body').hasClass('subsite')) {
      let oldText = $('h1.title').text();
      if (oldText.indexOf(": ") > 0) {
        newText = oldText.slice(oldText.indexOf(": ") + 2);
        $('h1.title').text(newText);
        $('.breadcrumbLink:last-of-type').text(newText);
        console.log('Title trimmed.');
      }
    }
  }, 100);
})